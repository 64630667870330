import React from 'react';
import { graphql } from 'gatsby';
/* eslint-enable */

import PropTypes from 'prop-types';
import { SectionStyles, Paragraph } from 'aether-marketing';
import footer from '../components/Shared/footer';
import Layout from '../components/layout';
import SEO from '../components/seo';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

/* eslint-disable no-script-url */
function NotFoundPage({ data }) {
  return (
    <Layout>
      <SEO
        title="404: Not found"
        canonical="/"
        image="https://voyager.postman.com/social-preview/postman-api-platform-social-preview-2.jpeg"
      />
      <SectionStyles>
        <div className="container">
          <div className="row">
            <div className="col-md-8 align-self-center pr-md-5">
              <img
                src="https://voyager.postman.com/illustration/postman-404-illustration.svg"
                className="img-fluid"
                alt="Postmanauts at a bar in space. Illustration."
              />
            </div>
            <div className="col-md-4 align-self-center">
              <h1>404</h1>
              <Paragraph className="subtitle">
                Oops! We can&apos;t find this page. It may have been moved.
              </Paragraph>
            </div>
          </div>
        </div>
      </SectionStyles>
      {JSON.parse(data.globalValues.content).map((item) => {
        if (item.type === 'footer') {
          return <div key={pmUuid()}>{footer(item)}</div>;
        }
        return null;
      })}
    </Layout>
  );
}
/* eslint-enable */

export default NotFoundPage;

export const query = graphql`
  query {
    globalValues {
      content
    }
  }
`;

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
    globalValues: PropTypes.shape({
      content: PropTypes.string,
      url: PropTypes.string,
      text: PropTypes.string
    })
  }).isRequired
};
